@import "https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0/css/materialize.min.css";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav {
  height:auto;
}

a.menu-link{
  color:black
}

div.noteview{
  padding-bottom: 2%;
}

button:focus {
  background-color:gray;
}

.focusWhite {
  color:black;
}

.focusWhite:focus {
  color: white;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.flex-container-col {
  display:flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.menu {
  display: flex;
  flex: auto;
}

.centered {
  display: flex;
  justify-content: center;
}

.items {
  cursor: pointer;
}

.toggleBtn {
  display: none;
}

.childPadding > * {
  padding: 0px 10px;
}

@media screen and (max-width: 500px) {
  .mobileFlex {
    flex-direction: column;
  }

  .mobileWidth {
    width: 100%;
  }

  .list {
    flex-direction: column;
    flex: auto;
  }
  .items:nth-child(1){
    border-top: 1px solid rgba(255, 255, 255, 0.555);
  }
  .items {
      width: 100%;
      border-top: 1px solid rgba(255, 255, 255, 0.555);
      text-align: center;
      margin-right: 0px;
  }
  .toggleBtn {
    display: block;
  }
}